@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}
@font-face {
  font-family: "Logo Font";
  src: url("/icons/KaushanScript-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bu-bg-title {
  background-color: #1c5b5f;
}
.dark {
  .bu-bg-title {
    @apply bg-pink-600;
  }
}

// .bu-color-1 {
//   @apply text-white;
// }

// .dark {
//   .bu-color-1 {
//     @apply text-gray-900;
//   }
// }

.bu-bg-color {
  background-color: #ebebeb;
  color: #ebebeb;
}

.dark {
  .bu-bg-color {
    @apply bg-gray-900;
    @apply text-gray-900;
  }
}

.bu-nav-color {
  background-color: #dedede;
  border-color: #dedede;
  @apply text-gray-900;
}

.dark {
  .bu-nav-color {
    @apply bg-slate-800;
    @apply border-slate-800;
    @apply text-white;
  }
}

.bu-text-primary {
  @apply text-gray-900;
}

.dark {
  .bu-text-primary {
    @apply text-white;
  }
}

.bu-text-secondary {
  @apply text-white;
}

.dark {
  .bu-text-secondary {
    @apply text-gray-900;
  }
}

.bu-text-primary-hover {
  @apply text-gray-900;
  &:hover {
    color: #1c5b5f;
    fill: #1c5b5f;
  }
}

.dark {
  .bu-text-primary-hover {
    @apply text-white;
    &:hover {
      @apply text-pink-500;
    }
  }
}

.bu-text-title {
  color: #1c5b5f;
  fill: #1c5b5f;
  border-color: #1c5b5f;
}

.dark {
  .bu-text-title {
    @apply text-pink-500;
    @apply fill-pink-500;
    @apply border-pink-500;
  }
}

.bu-text-subtitle {
  @apply text-gray-500;
}

.dark {
  .bu-text-subtitle {
    @apply text-gray-500;
  }
}

.bu-text-disable {
  @apply text-gray-400;
}

.dark {
  .bu-text-disable {
    @apply text-gray-500;
  }
}

.bu-button {
  @apply transition-colors;
  @apply duration-300;
  @apply ease-in-out;
  @apply text-gray-900;
  &:active {
    @apply ring-4;
  }
}ww

.dark {
  .bu-button {
    @apply text-white;
  }
}

.bu-button-primary {
  // If on card
  @extend .bu-button;
  background-color: #aadfcf;
  &:hover {
    background-color: #84cfb8;
  }
  &:active {
    --tw-ring-color: #aadfcf;
  }
}

.dark {
  .bu-button-primary {
    // If on card
    @apply bg-pink-600;
    &:hover {
      @apply bg-pink-700;
    }
    &:active {
      @apply ring-pink-600;
    }
  }
}

.bu-button-secondary {
  // If on bg
  @extend .bu-button;
  background-color: #94cebc;
  &:hover {
    background-color: rgb(124, 193, 172);
  }
  &:active {
    --tw-ring-color: #94cebc;
  }
}

.dark {
  .bu-button-secondary {
    // If on bg
    @apply bg-pink-700;
    &:hover {
      @apply bg-pink-800;
    }
    &:active {
      @apply ring-pink-700;
    }
  }
}

.bu-button-save {
  // If on bg
  @extend .bu-button;
  background-color: #74cde9;
  &:hover {
    background-color: #46bbdf;
  }
  &:active {
    --tw-ring-color: #74cde9;
  }
}

.dark {
  .bu-button-save {
    // If on bg
    @apply bg-blue-600;
    &:hover {
      @apply bg-blue-700;
    }
    &:active {
      @apply ring-blue-600;
    }
  }
}

.bu-button-delete {
  @extend .bu-button;
  background-color: #ef9c9c;
  &:hover {
    background-color: #e17171;
  }
  &:active {
    --tw-ring-color: #ef9c9c;
  }
}

.dark {
  .bu-button-delete {
    // If on card
    @apply bg-blue-600;
    &:hover {
      @apply bg-blue-700;
    }
    &:active {
      // @apply bg-blue-700;
      @apply ring-blue-600;
    }
  }
}

.bu-text-subtitle-hover {
  @apply text-gray-400;
  &:hover {
    @apply text-gray-900;
  }
}

.dark {
  .bu-text-subtitle-hover {
    @apply text-gray-400;
    &:hover {
      @apply text-white;
    }
  }
}

.bu-horizontal-bar {
  @apply border-gray-600;
}

.dark {
  .bu-horizontal-bar {
    @apply border-gray-600;
  }
}

.bu-card-primary {
  background-color: #ffffff;
  border-color: #ffffff;
}

.dark {
  .bu-card-primary {
    @apply bg-gray-700;
    @apply border-gray-700;
  }
}

.bu-card-secondary {
  background-color: #ffffff;
  border-color: #ffffff;
}

.dark {
  .bu-card-secondary {
    @apply bg-gray-800;
    @apply border-gray-800;
  }
}

.bu-input-primary {
  @apply focus:ring-green-800;
  @apply focus:border-green-800;
  @apply placeholder-gray-400;
  @apply text-gray-900;
  @apply focus:ring-2;
}

.dark {
  .bu-input-primary {
    @apply focus:ring-pink-600;
    @apply focus:border-pink-600;
    @apply placeholder-gray-400;
    @apply text-white;
    @apply bg-gray-700;
    @apply border-gray-600;
  }
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.dark-mode {
  --icon-color: #ffffff; /* Set your dark mode icon color */
  // --background-color: #333333; /* Set your dark mode background color */
}

.light-mode {
  --icon-color: #000000; /* Set your light mode icon color */
  // --background-color: #ffffff; /* Set your light mode background color */
}

.icon-container {
  color: var(--icon-color);
  background-color: var(--background-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.hbox {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.vbox {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.no-ring-input {
  input:focus {
    @apply ring-0;
  }
}

.input-label-black {
  label {
    // color: #000000 !important;
  }
}
.scrollable-dropdown-menu {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
}
