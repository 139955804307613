.leaderboard {
  // max-width: 490px;
  width: 100%;
  border-radius: 12px;

  header {
    --start: 3rem;
    height: 130px;
    // background-image:
    // // repeating-radial-gradient(
    // //     circle at var(--start),
    // //     transparent 0%,
    // //     transparent 10%,
    // //     #96cdbf 10%,
    // //     #96cdbf 17%
    // //   ),
    //   linear-gradient(to right, #aadfcf, #96cdbf);
    background-color: #aadfcf;
    color: #fff;
    position: relative;
    border-radius: 12px 12px 0 0;
    overflow: hidden;

    .leaderboard__title {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: calc(var(--start) * 0.75);
      transform: translateY(-50%);
      text-transform: uppercase;
      margin: 0;
      color: #000;
      span {
        display: block;
      }

      &--top {
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 4px;
      }

      &--bottom {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 3.55px;
        opacity: 0.65;
        transform: translateY(-2px);
      }
    }

    .leaderboard__icon {
      fill: #000;
      opacity: 0.8;
      width: 60px;
      position: absolute;
      font-weight: bold;
      top: 50%;
      left: var(--start);
      transform: translate(-50%, -50%);
    }
  }

  &__profiles {
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    padding: 15px 15px 20px;
    display: grid;
    row-gap: 8px;
  }

  &__profile {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 3px 5px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition:
      transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
      box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 5px 17px -1px rgba(51, 51, 51, 0.18);
    }
  }

  &__picture {
    max-width: 100%;
    width: 60px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    box-shadow:
      0 0 0 10px #ebeef3,
      0 0 0 22px #f3f4f6;
    object-fit: cover;
  }

  &__name {
    color: #000;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.64px;
    // margin-left: 12px;
  }

  &__value {
    color: #000;
    font-weight: 700;
    font-size: 34px;
    text-align: right;

    & > span {
      opacity: 0.8;
      font-weight: 600;
      font-size: 13px;
      margin-left: 3px;
    }
  }
}

// bare minimuu styles

// body {
//   margin: 0;
//   background-color: #eaeaea;
//   display: grid;
//   height: 100vh;
//   place-items: center;
//   font-family: "Source Sans Pro", sans-serif;
// }

.leaderboard {
  // box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
}
